import React, { useState, useEffect, useContext } from "react"
import { Auth } from "aws-amplify"
import { useForm } from "react-hook-form"
import "twin.macro"
import Button from "../components/button"
import { navigate } from "gatsby"
import AccountLayout from '../components/accountLayout'
import UiStoreContext from "../contexts/uiStoreContext"
import { useUser } from "../hooks/useUser"
import { useApi } from "../hooks/useApi"
import { toast, Zoom } from 'react-toastify'
import SEO from "../components/seo"

const Verify = ({ location }) => {

	const { register, errors, handleSubmit } = useForm();
	const [codeSent, setCodeSent] = useState(false)
	const [verificationErr, setVerificationErr] = useState()
	const uiStore = useContext(UiStoreContext)
	const email = location && location.state && location.state.email ? location.state.email : null;
	const { user, refreshAuthenticatedUser, logout } = useUser()
	const [spin, setSpin] = useState(false)
	const { callApi } = useApi()

	useEffect( () => {
		if (codeSent) {
			setTimeout(() => {
				setCodeSent(false)
			},2000)
		}
	}, [codeSent])

	useEffect( () => {
		if (!email) {
			navigate('/login')
		}
	})

	const onSubmit = async (data) => {
		setSpin(true)
		// either directly after sign up or after a login and before initial confirmation
		if (location.state.notConfirmed) {
			Auth.confirmSignUp(location.state.email, data.code)
				.then(() => {
					uiStore.setGate({
						preAuthParams: {state: {email: location.state.email}},
						postAuthLocation: uiStore.gate ? (uiStore.gate.postAuthLocation || '/thankyou') : '/thankyou'
					})
				})
				.catch(err => {
					console.log(err)
					setVerificationErr(err.message)
				})
				.finally(() => {
					setSpin(false)
				}) 
		}
		// following email address attribute change
		else {
			try {
				await callApi({
					method: "post",
					namespace: "me/email_verification",
					payload: {
						accessToken: user.cognitoUser.signInUserSession.accessToken.jwtToken,
						code: data.code
					}
				})
				await refreshAuthenticatedUser({ bypassCache: true })
				toast.info('Your new email address has been verified!', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					transition: Zoom
				})
				navigate('/account/profile')
			}
			catch(err) {
				console.log(err)
				setVerificationErr(err.message)
			}
			finally {
				setSpin(false)
			}
		}
	}

	const handleResendCode = (evt) => {
		evt.preventDefault()
		if (location.state.notConfirmed) {
			Auth.resendSignUp(location.state.email)
				.then( () => {
					setCodeSent(true)
				})
				.catch(err => {
					console.log(err)
					setVerificationErr(err.message)
				})
		}
		else {
			Auth.verifyCurrentUserAttribute("email")
				.then( () => {
					setCodeSent(true)
				})
				.catch(err => {
					console.log(err)
					setVerificationErr(err.message)
				})
		}
	}
	
	const verifyForm = email && <>
		<SEO title="Verify Email"/>
		<AccountLayout onSubmit={handleSubmit(onSubmit)} message={'Please verify your email address'} instructions={
			<div tw="">A verification code was sent to {location.state.email}. Please enter it below.</div>
		}>
			<label htmlFor="code">Email verification code</label>
			<input
				name="code"
				type="text"
				placeholder="Verification Code"
				ref={register({
					required: true,
					pattern: /^[0-9]+$/
				})}
			></input>
			{errors.code && <div className="error">Please enter a numeric verification code</div>}
			{verificationErr && <div className="error">{verificationErr}</div>}
			<Button 
				tw="w-full mt-2" 
				type="submit" 
				special={true}
				spin={spin}
			>Continue</Button>
			<div tw="mt-8 w-full"><Button tw="w-full" onClick={handleResendCode}>{codeSent ? "Code Sent!" : "Resend Code"}</Button></div>
		</AccountLayout>
	</>

	return email ? verifyForm : <div></div>

}

export default Verify